<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'Items' })"
            v-if="!$route.params.meeting"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Items
          </v-btn>
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'MeetingItems' })"
            v-if="$route.params.meeting"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Meeting Items
          </v-btn>
          <h2 class="darkGrey--text d-flex flex-column custom-section-title">
            <span>Attachments of <span class="font-weight-black">{{ item.title }}</span></span>
            <p class="text-body-1">for {{ boardName }}</p>
          </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Board Members
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('create')"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Attachment
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card
        >
          <v-data-table
            :headers="headers"
            hide-default-header
            hide-default-footer
            :items="attachments"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
          >
            <template v-slot:item.sortable>
              <v-icon color="lightGrey">
                mdi-drag-horizontal-variant
              </v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              <span class="d-flex py-6">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-row justify-end">

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="viewItem(item)"
                    >
                      <v-icon size="20">
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    View document
                  </span>
                </v-tooltip>

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenu"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          color="darkGrey"
          elevation="0"
          class="py-14 d-flex justify-center"
        >
          <v-card
            color="white"
            tile
            style="width: 4.25in; height: 5.5in"
          >
            <iframe style="height: 100%; width: 100%" :src="previewUrl" />
          </v-card>
        </v-card>
      </v-col>
    </v-row>

    <item-attachments-create @reload="getItemFromDatabase" v-model="modals.create" :item="item"></item-attachments-create>
    <item-attachments-edit @reload="getItemFromDatabase" v-model="modals.edit" :attachment="attachment" :item="item"></item-attachments-edit>
    <item-attachments-replace @reload="getItemFromDatabase" v-model="modals.replace" :attachment="attachment" :item="item"></item-attachments-replace>
    <item-attachments-delete @reload="getItemFromDatabase" v-model="modals.delete" :attachment="attachment" :item="item"></item-attachments-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import ItemAttachmentsCreate from '@/components/Items/ItemAttachmentsCreate'
import ItemAttachmentsEdit from '@/components/Items/ItemAttachmentsEdit'
import ItemAttachmentsReplace from '@/components/Items/ItemAttachmentsReplace'
import ItemAttachmentsDelete from '@/components/Items/ItemAttachmentsDelete'
import Item from '@/models/Item'
import axios from 'axios'
import FileDownload from 'js-file-download'

export default Vue.extend({
  name: 'ItemAttachments',
  components: {
    Portal,
    ItemAttachmentsCreate,
    ItemAttachmentsEdit,
    ItemAttachmentsReplace,
    ItemAttachmentsDelete
  },
  props: {
    itemId: String
  },
  data: () => ({
    item: {},
    modeType: '',
    attachments: [],
    attachment: {},
    previewUrl: '',
    modals: {
      create: false,
      edit: false,
      replace: false,
      delete: false
    },
    breadcrumbsItems: [
      {
        text: 'Items',
        disabled: false,
        href: ''
      },
      {
        text: 'Item Attachments',
        disabled: true,
        href: '/boards'
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'edit' },
      { icon: 'mdi-paperclip', text: 'Replace document', actions: 'replace' },
      { icon: 'mdi-download', text: 'Download', actions: 'download' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: '', value: 'sortable' },
      { text: 'Label', value: 'name' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ],
    attachmentsSample: [
      { name: 'Russell document' },
      { name: 'Document' },
      { name: 'Sample name of document bigger than usual' },
      { name: 'Doc' },
      { name: 'Example of document' },
      { name: 'Document Big' },
      { name: 'Document Even Bigger' },
      { name: 'Sample document' },
      { name: 'Short doc' }
    ]
  }),
  computed: {
    boardName () {
      return this.item.board?.name
    }
  },
  methods: {
    viewItem (item) {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/item-attachment-get-image/' + item.id)
        .then((res) => {
          console.log(res.data)
          this.previewUrl = res.data
        })
      console.log('view item', item)
    },
    openModal (item, optional, attachment) {
      if (item === 'download') {
        this.downloadItem(attachment)
        return false
      }
      if (optional) this.modeType = optional
      if (attachment) this.attachment = attachment
      this.modals[item] = true
    },
    downloadItem (attachment) {
      axios({
        url: process.env.VUE_APP_BACKEND_URL + '/api/item-attachment-get-image/' + attachment.id,
        method: 'GET',
        responseType: 'blob'
      }).then(({ data }) => {
        console.log('data', data)
        const downloadUrl = window.URL.createObjectURL(new Blob([data]))

        const link = document.createElement('a')

        link.href = downloadUrl

        link.setAttribute('download', 'file.pdf') // any other extension

        document.body.appendChild(link)

        link.click()

        link.remove()

        // console.log('res', res.data)
        // const blob = new Blob([res.data], { type: 'application/pdf' })
        // console.log('new blob', blob)
        // FileDownload(blob, attachment.name + '.pdf')
        // const bl = new Blob([res.data], { type: 'application/pdf' })
        // const a = document.createElement('a')
        // a.href = URL.createObjectURL(bl)
        // a.download = attachment.name
        // a.hidden = true
        // document.body.appendChild(a)
        // a.click()
        // console.log('res', res.data)
        // // const blob = new Blob([res.data], { type: 'application/pdf' })
        // // const url = window.URL.createObjectURL(blob)
        // // const link = document.createElement('a')
        // // link.href = url
        // // link.setAttribute('download', 'file.pdf')
        // // document.body.appendChild(link)
        // // link.click()
        // // const blob = new Blob([res.data], { type: 'application/pdf' })
        // // const link = document.createElement('a')
        // // console.log('blob', blob)
        // // link.href = URL.createObjectURL(blob)
        // // link.download = attachment.name
        // // link.click()
        // // URL.revokeObjectURL(link.href)
        // const url = window.URL.createObjectURL(new Blob([res.data]))
        // const link = document.createElement('a')
        // link.href = url
        // link.setAttribute('download', attachment.name + '.pdf')
        // document.body.appendChild(link)
        // link.click()
      }).catch(error => {
        console.log('err', error)
      })
    },
    async getItemFromDatabase () {
      this.item = await Item
        .where('id', this.itemId)
        .include(['board', 'user', 'attachments'])
        .first()
      this.attachments = this.item.attachments
    }
  },
  async mounted () {
    await this.getItemFromDatabase()
  }
})
</script>
