<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Replace Attachment <span class="font-weight-bold">ATTACHMENT NAME</span> of <span>{{ item.title }}</span></span>
        <span>for <span class="font-weight-black">{{ boardName }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>
            <v-file-input
              label="Click or drag/drop to add file"
              prepend-icon="mdi-cloud-upload"
              @change="fileUploaded"
              show-size
              :rules="rules.fileRules">
              <v-card
                elevation="0"
                class="d-flex align-center justify-center"
                height="200"
                style="border: 1px dashed rgba(0,0,0,0.2);"
              >
                <v-icon color="lightGrey">mdi-cloud-upload</v-icon>
                <span class="lightGrey--text d-flex ml-4 text-caption text-uppercase">Click or drag/drop to add file</span>
              </v-card>
            </v-file-input>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false" class="mr-2">Cancel</v-btn>
        <v-btn depressed width="130" class="ml-2" color="secondary" @click="updateAttachment">Update</v-btn>
      </v-card-actions>

    </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

function initialState () {
  return {
    valid: false,
    saving: false,
    form: {
      file: ''
    },
    rules: {
      fileRules: [
        v => !!v || 'File is required',
        v => !v || v.size < 10000000 || 'Avatar size should be less than 10 MB!'
      ]
    }
  }
}

export default Vue.extend({
  name: 'ItemAttachmentsReplace',
  props: {
    item: {},
    attachment: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => initialState(),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    }
  },
  methods: {
    fileUploaded (file) {
      this.form.file = file
    },
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      Object.assign(this.$data, initialState())
      this.saving = false
    },
    updateAttachment () {
      const formPassedValidation = this.$refs.form.validate()
      if (formPassedValidation === false) {
        return false
      }
      this.saving = true
      const axiosAction = 'post'
      const axiosUrl = '/api/item-attachments-replace-image/' + this.attachment.id
      console.log('axios URL', axiosUrl)
      const data = new FormData()
      data.append('file', this.form.file)

      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*'
        }
      }).then(() => {
        this.$emit('reload', true)
        this.resetModal()
        this.show = false
      }).catch(error => {
        console.log('error', error)
        // console.log(error.response.data)
        // console.log(error.response.status)
        // console.log(error.response.headers)
      })
    }
  }
})
</script>
