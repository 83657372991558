<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Edit Attachment <span class="font-weight-bold">{{ attachment.name }}</span> of <span>{{ item.title }}</span></span>
        <span>for <span class="font-weight-black">{{ boardName }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="Label"
              v-model="form.name"
              :rules="rules.labelRules"
            ></v-text-field>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false" class="mr-2">Cancel</v-btn>
        <v-btn depressed width="130" class="ml-2" color="secondary" @click="updateAttachment">Update</v-btn>
      </v-card-actions>

    </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

function initialState () {
  return {
    valid: false,
    saving: false,
    form: {
      name: ''
    },
    rules: {
      labelRules: [
        v => !!v || 'Label is required',
        v => (v && v.length <= 1000) || 'Label must be less than 50 characters'
      ]
    }
  }
}

export default Vue.extend({
  name: 'ItemAttachmentsEdit',
  props: {
    item: {},
    attachment: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => initialState(),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    boardName () {
      return this.item.board?.name
    }
  },
  methods: {
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      Object.assign(this.$data, initialState())
      this.saving = false
    },
    updateAttachment () {
      const axiosAction = 'put'
      const axiosUrl = '/api/item-attachments/' + this.attachment.id

      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: this.form
      }).then(() => {
        console.log('success')
        this.resetModal()
        this.show = false
        this.$emit('reload', true)
      }).catch(error => {
        console.log('error', error)
        // console.log(error.response.data)
        // console.log(error.response.status)
        // console.log(error.response.headers)
      })
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.form.name = this.attachment.name
      }
    }
  }
})
</script>
